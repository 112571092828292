import PropTypes from 'prop-types'
import React from 'react'

const Header = props => (
  <header id="header" style={props.timeout ? { display: 'none' } : {}}>
    <div className="logo">
      <span className="icon fa-wrench"></span>
    </div>
    <div className="content">
      <div className="inner">
        <h1>Cipő ápolás</h1>
        <p>
          Kovács Gábor vagyok és Magyarországon egyedüliként nyújtok teljes körű, magas szintű, cipő ápolási szolgáltatást.
        </p>
      </div>
    </div>
    <nav>
      <ul>
        <li>
          <button
            onClick={() => {
              props.onOpenArticle('intro')
            }}
          >
            Bemutatkozás
          </button>
        </li>
        <li>
          <button
            onClick={() => {
              props.onOpenArticle('work')
            }}
          >
            Technika
          </button>
        </li>
        <li>
          <button
            onClick={() => {
              props.onOpenArticle('references')
            }}
          >
            Referenciák
          </button>
        </li>
        <li>
          <button
            onClick={() => {
              props.onOpenArticle('prices')
            }}
          >
            <storng>Árak</storng>
          </button>
        </li>
        <li>
          <button
            onClick={() => {
              props.onOpenArticle('order')
            }}
          >
            <storng>Rendelés</storng>
          </button>
        </li>
        <li>
          <button
            onClick={() => {
              props.onOpenArticle('partners')
            }}
          >
            Partnerek
          </button>
        </li>
      </ul>
    </nav>
    <ul className="icons">
      <li>
        <a href="mailto:kgbzs123@gmail.com?Subject=Cip%C5%91%C3%A1pol%C3%A1s%20megrendel%C3%A9se" rel="noopener noreferrer" target="_blank" className="icon fa-envelope-o">
          <span className="label">Email</span>
        </a>
      </li>
      <li>
        <a href="https://www.facebook.com/cipoapolomester/" target="_blank" rel="noopener noreferrer" className="icon fa-facebook">
          <span className="label">Facebook</span>
        </a>
      </li>
      <li>
        <a href="https://www.youtube.com/channel/UCcIx4Qmh1BPTneb4k_9MgIQ?view_as=subscriber" rel="noopener noreferrer"
        target="_blank" className="icon fa-youtube">
          <span className="label">Youtube</span>
        </a>
      </li>
      <li>
        <a href="https://www.instagram.com/cipoapolomester_shiningmaster/" rel="noopener noreferrer"
        target="_blank" className="icon fa-instagram">
          <span className="label">Instagram</span>
        </a>
      </li>
    </ul>
  </header>
)

Header.propTypes = {
  onOpenArticle: PropTypes.func,
  timeout: PropTypes.bool,
}

export default Header
