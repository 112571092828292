import PropTypes from 'prop-types'
import React from 'react'
import workPic from '../images/work-pic.jpg'
import orderPic from '../images/cipos1.jpg'
import before1 from '../images/before-1.jpg'
import before2 from '../images/before-2.jpg'
import before3 from '../images/before-3.jpg'
import before4 from '../images/before-4.jpg'
import after1 from '../images/after-1.jpg'
import after2 from '../images/after-2.jpg'
import after3 from '../images/after-3.jpg'
import after4 from '../images/after-4.jpg'
import introducePic from '../images/introduce.jpg'

class Main extends React.Component {
  render() {
    let close = (
      <div
        className="close"
        onClick={() => {
          this.props.onCloseArticle()
        }}
      ></div>
    )

    return (
      <div
        ref={this.props.setWrapperRef}
        id="main"
        style={this.props.timeout ? { display: 'flex' } : { display: 'none' }}
      >
        <article
          id="intro"
          className={`${this.props.article === 'intro' ? 'active' : ''} ${
            this.props.articleTimeout ? 'timeout' : ''
          }`}
          style={{ display: 'none' }}
        >
          <h2 className="major">Bemutatkozás</h2>
          <span className="image main">
            <img src={introducePic} alt="" />
          </span>
          <p>
            Kovács Gábor vagyok és Magyarországon egyedüliként nyújtok teljes körű, magas szintű, cipő ápolási szolgáltatást.
          </p>
          <p>
            Fontosnak tartom az igényes megjelenést, aminek egy gyönyörű, csillogó, ápolt cipő nélkülözhetetlen része.
            Aki rendszeresen használja a cipőjét, de elfoglaltsága miatt nincs ideje vagy türelme annak ápolására, azok részére szeretnék segítséget nyújtani, hogy mindig tökéletes legyen a megjelenésük és az ápolásnak köszönhetően sokáig tudják hordani a kedvenc lábbelijüket.
          </p>
          <p>
            Ha igénybe szeretné venni a szolgáltatásomat, kérem keressen meg személyesen vagy küldje el a cipőjét a megadott címre.
          </p>
          <p>
            A cipő ápolását követően visszajuttatom a megadott címre a lábbeliét, ami egyedül állóan fog csillogni és garantáltan vonzani a szemeket…
          </p>
          <p>
            Köszönöm, hogy megtisztel a bizalmával és igénybe veszi a szolgáltatásomat!
          </p>
          {close}
        </article>

        <article
          id="work"
          className={`${this.props.article === 'work' ? 'active' : ''} ${
            this.props.articleTimeout ? 'timeout' : ''
          }`}
          style={{ display: 'none' }}
        >
          <h2 className="major">Technika</h2>
          <span className="image main">
            <img src={workPic} alt="" />
          </span>
          <p>
            Elsősorban marha bőrből, borjú bőrből készült férfi és női cipők ápolását vállalom. Egzotikus bőrökből, velur bőrből készült cipők ápolása előtt kérem vegye fel a kapcsolatot velem.
          </p>
          <p>
            A Shoeshine (cipőfényesítő) világbajnoki győztes Japán mesterek Naoki Terashima és Gou Ishimi technikáit alkalmazom a cipők ápolásánál.
          </p>
            Csak a legjobb minőségű Francia Saphir, valamint Japán Boot Black szereket használom fel.
          <p>
          </p>
            A cipők ápolása az alábbi több lépcsős folyamatból áll:
            <ul>
              <li>cipő tapp, belső rész, külső felület tisztítása</li>
              <li>cipő hidratáló krémmel való ápolása</li>
              <li>cipő ápolása, szín azonos krémmel</li>
              <li>cipő talp élének ápolása</li>
              <li>cipő vaxolása, fényesítése</li>
              <li>cipő orr és sarok részénél a tükör fény kialakítása</li>
            </ul>
            <p>
              Ezeknél a folyamatoknál különböző szőrű keféket (ló, sertés, kecske) valamint speciális fényesítő, polírozó kendőket használok fel a tökéletes cél elérése érdekében.
            </p>
            <p>
              A folyamat 1-3 órát vesz igénybe (a cipő állapotától függően).
            </p>
          {close}
        </article>

        <article
          id="order"
          className={`${this.props.article === 'order' ? 'active' : ''} ${
            this.props.articleTimeout ? 'timeout' : ''
          }`}
          style={{ display: 'none' }}
        >
          <h2 className="major">Rendelés</h2>
          <span className="image main">
            <img src={orderPic} alt="" />
          </span>
          <p>
            Személyesen vagy futár céggel jutassa el az alábbi címre a cipőket:
          </p>
          <p className="centerText">
          <strong>Kovács Gábor, <br />6724, Szeged, Teréz u. 33</strong>
          <br />
          Email: <strong>kgbzs123@gmail.com</strong>
          <br />
          Tel: <strong>+36-70-3732359</strong>
          </p>
          <p>
            Vagy kedvező áron az alábbi Foxpost terminálba is küldheti a cipőket:
          </p>
          <ul>
            <li>Szeged Árkád</li>
            <li>Szeged Tesco (Rókusi krt.)</li>
          </ul>
          <p>
            <strong>
              Kérem, a csomagon pontosan feltüntetni a feladó saját adatait, ahova vissza küldöm a cipőket (név, cím, telefon szám)!
            </strong>
          </p>
          <hr />
          <h4>Kérem adja meg adatait</h4>
          <form name="order" method="POST" data-netlify="true" data-netlify-honeypot="bot-field" action="/form-success">
            <input type="hidden" name="form-name" value="order" />
            <div className="field half first">
              <label htmlFor="name">Név</label>
              <input type="text" name="name" id="name" required/>
            </div>
            <div className="field half">
              <label htmlFor="email">Email</label>
              <input type="email" name="email" id="email" required/>
            </div>
            <div className="field">
              <label htmlFor="phone">Telefonszám</label>
              <input type="text" name="phone" id="phone" required/>
            </div>
            <div className="field">
              <label htmlFor="address">Cím</label>
              <input type="text" name="address" id="address" placeholder="Foxpost esetén a terminálé" required/>
            </div>
            <div className="field half first">
              <label htmlFor="species">Cipő fajtája</label>
              <input type="text" name="species" id="species" required/>
            </div>
            <div className="field half">
              <label htmlFor="color">Cipő színe</label>
              <input type="text" name="color" id="color" required/>
            </div>
            <ul className="actions">
              <li>
                <input type="submit" value="Küldés" className="special" />
              </li>
              <li>
                <input type="reset" value="Töröl" />
              </li>
            </ul>
          </form>
          <hr />
          <p className="centerText">
          Kérdés esetén, keressem meg a megadott elérhetőségek bármelyikén!
          </p>
          {close}
        </article>

        <article
          id="references"
          className={`${this.props.article === 'references' ? 'active' : ''} ${
            this.props.articleTimeout ? 'timeout' : ''
          }`}
          style={{ display: 'none' }}
        >
          <h2 className="major">Referenciák</h2>
          <h5>Cordwainer</h5>
          <span className="image main">
            <img src={before1} alt="" />
          </span>
          <span className="image main">
            <img src={after1} alt="" />
          </span>
          <h5>Profession Bottier</h5>
          <span className="image main">
            <img src={before2} alt="" />
          </span>
          <span className="image main">
            <img src={after2} alt="" />
          </span>
          <h5>Magnanni</h5>
          <span className="image main">
            <img src={before3} alt="" />
          </span>
          <span className="image main">
            <img src={after3} alt="" />
          </span>
          <h5>Lasocki</h5>
          <span className="image main">
            <img src={before4} alt="" />
          </span>
          <span className="image main">
            <img src={after4} alt="" />
          </span>
          <h5>Munkafolyamat</h5>
          <p className="centerText">
            <iframe title="embedVideos" width="100%" height="315" src="https://www.youtube.com/embed/_IQW0U4zk7M" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
          </p>
          {close}
        </article>

        <article
          id="prices"
          className={`${this.props.article === 'prices' ? 'active' : ''} ${
            this.props.articleTimeout ? 'timeout' : ''
          }`}
          style={{ display: 'none' }}
        >
          <h2 className="major">Árak</h2>
          <h4>PRÉMIUM TÜKÖR FÉNYŰ CIPŐ ÁPOLÁS</h4>
          <ul>
            <li>10 000 Ft-tól</li>
          </ul>
          <h4>Női cipő</h4>
          <ul>
            <li>6 000 Ft-tól</li>
          </ul>
          <p>
            A cipő ápolását egy héten belül, gyors határidővel elkészítem!
          </p>
          <p>
            A szolgáltatás kifizetése az elkészült cipő visszaküldését követően a futár cégnek vagy a FOXPOST terminálnál történik.
          </p>
          <p>
            <strong>A futár kézbesítés és utánvételi költséget a szolgáltatás nem tartalmazza!</strong>
            <br />
            (Kedvező árú FOXPOST céggel küldöm a csomagját).
          </p>
          <p>
            Ha a cipő nem dobozba kerül elküldésre, akkor a cipőket papírdobozba csomagolva küldöm vissza (a sérülések elkerülése végett), aminek a plusz költsége <strong>300.- Ft</strong>.
          </p>
          Kérdés esetén keressen bizalommal!
          {close}
        </article>
        <article
          id="partners"
          className={`${this.props.article === 'partners' ? 'active' : ''} ${
            this.props.articleTimeout ? 'timeout' : ''
          }`}
          style={{ display: 'none' }}
        >
          <h2 className="major">Partnerek</h2>
          <ul>
            <li><a href="https://www.shoeclubformen.com" rel="noopener noreferrer" target="_blank">Shoeclubformen kézműves minőségű férficipők: https://www.shoeclubformen.com/</a></li>
            <li><a href="https://gentrystore.hu" rel="noopener noreferrer" target="_blank">Gentrystore  modern és elegáns divatkiegészítők egyenesen Kaliforniából: https://gentrystore.hu/</a></li>
            <li><a href="https://www.facebook.com/handmadeshoesbespoken" rel="noopener noreferrer" target="_blank">Sallay Handmade Shoes: https://www.facebook.com/handmadeshoesbespoken</a></li>
          </ul>
          {close}
        </article>
      </div>
    )
  }
}

Main.propTypes = {
  route: PropTypes.object,
  article: PropTypes.string,
  articleTimeout: PropTypes.bool,
  onCloseArticle: PropTypes.func,
  timeout: PropTypes.bool,
  setWrapperRef: PropTypes.func.isRequired,
}

export default Main
